'use client';

import { useClockSettingsStore } from '@/store/timeStore';
import { useCitiesStore } from '@/store/citiesStore';
import { ReactNode } from 'react';

interface StoreHydrationProps {
  children: ReactNode;
}

export default function StoreHydration({ children }: StoreHydrationProps) {
  const clockSettingsHydrated = useClockSettingsStore((state) => state.isHydrated);
  const citiesStoreHydrated = useCitiesStore((state) => state.isHydrated);

  if (!clockSettingsHydrated || !citiesStoreHydrated) {
    // You might want to add a loading spinner or skeleton here
    return <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500" />
    </div>;
  }

  return <>{children}</>;
} 