'use client';

import { useEffect } from 'react';
import { useClockSettingsStore } from '@store/timeStore';

export function OverflowHandler() {
  const { globalIsDragging } = useClockSettingsStore();

  useEffect(() => {
    document.body.style.overflow = globalIsDragging ? 'hidden' : '';
  }, [globalIsDragging]);

  return null;
} 